import { Button, useRefresh, useDataProvider, useNotify } from 'react-admin'

const styles = {
  button: { margin: '5px 0', minWidth: 90 },
  buttonReject: { backgroundColor: 'red' },
}

const ExpertBookingActionButton = props => {
  const { record, action, ...rest } = props
  const notify = useNotify()
  const refresh = useRefresh()
  const dataProvider = useDataProvider()

  const getDataUpdate = () => {
    switch (action) {
      case 'paid':
        return {
          status: 'paid',
        }
      case 'booked':
        return {
          status: 'booked',
        }
      case 'completed':
        return {
          status: 'completed',
        }
      case 'canceled':
        return {
          status: 'canceled',
        }
      default:
        return {}
    }
  }

  const handleClick = async () => {
    const data = getDataUpdate()

    dataProvider
      .update('expert-bookings', { id: record.id, data })
      .then(() => {
        notify('Booking updated', { type: 'success' })
        refresh()
      })
      .catch(error => {
        notify(`Error: Booking not updated ${error.message}`, { type: 'error' })
      })
  }

  return (
    <Button
      sx={
        action === 'canceled'
          ? { ...styles.buttonReject, ...styles.button }
          : styles.button
      }
      color={rest.color}
      variant="contained"
      onClick={handleClick}
      label={rest.label}
    />
  )
}

export default ExpertBookingActionButton
