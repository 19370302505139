import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  ReferenceField,
  DateField,
  TextInput,
  usePermissions,
} from 'react-admin'

import FilterComponent from '../../components/Filter'
import CustomSwitchField from '../../components/CustomSwitchField'
import { ROLES } from '../../components/constants'

const AuthorAffiliateFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </FilterComponent>
)

const AuthorAffiliateList = props => {
  const { permissions } = usePermissions()
  return (
    <List
      {...props}
      perPage={1000}
      filters={<AuthorAffiliateFilter />}
      sort={{ field: 'updatedAt', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="id" />
        <ReferenceField source="authorId" reference="authors">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="publisherId" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <TextField
          source="totalPurchasedCollection"
          label="Purchased Collection"
        />
        <TextField
          source="totalRedeemedCollection"
          label="Redeemed Collection"
        />
        <TextField source="totalPurchasedCourse" label="Purchased Course" />
        <TextField source="totalRedeemedCourse" label="Redeemed Course" />
        <TextField source="totalPurchasedBook" label="Purchased Book" />
        <TextField source="totalRedeemedBook" label="Redeemed Book" />
        <DateField label="Start at" source="startAt" />
        <DateField label="Expire at" source="expireAt" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <CustomSwitchField
          resource="affiliates"
          label="Status"
          switchField="status"
          disabled={
            ![
              ROLES.ADMIN,
              ROLES.CPSO,
              ROLES.MARKETING_MANAGER,
              ROLES.STATISTICAL_STAFF,
            ].includes(permissions)
          }
        />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  )
}

export default AuthorAffiliateList
