import { DeleteButton, usePermissions, useRecordContext } from 'react-admin'
import Grid from '@mui/material/Grid'

import { ROLES } from '../constants'

import ActionButton from './ActionButton'

const ApproveButtons = ({ service, statusField = 'status' }) => {
  const record = useRecordContext()
  const { permissions } = usePermissions()

  const approveBtns = () => (
    <Grid container>
      <Grid container xs={6} direction="column">
        <ActionButton
          label="Approve"
          action="approve"
          color="success"
          service={service}
          record={record}
          statusField={statusField}
        />
        <ActionButton
          label="Reject"
          action="reject"
          color="error"
          service={service}
          record={record}
          statusField={statusField}
        />
        <DeleteButton record={record} />
      </Grid>
    </Grid>
  )

  if (
    !record ||
    ![
      ROLES.ADMIN,
      ROLES.MARKETING_MANAGER,
      ROLES.STATISTICAL_STAFF,
      ROLES.HEAD_OF_CUSTOMER_SERVICE,
      ROLES.CS_MARKETING_MANAGER,
    ].includes(permissions) ||
    ['approved', 'rejected'].includes(record[statusField])
  )
    return null

  switch (permissions) {
    case ROLES.ADMIN:
    case ROLES.MARKETING_MANAGER:
    case ROLES.STATISTICAL_STAFF:
    case ROLES.HEAD_OF_CUSTOMER_SERVICE:
    case ROLES.CS_MARKETING_MANAGER: {
      if (!record.approveRole) return approveBtns()
      return null
    }
    default:
      return null
  }
}

export default ApproveButtons
