import { forwardRef } from 'react'
import {
  RichTextInput as RaRichTextInput,
  RichTextInputProps,
} from 'ra-input-rich-text'

const removeParagraphListItem = (html: any) =>
  html?.replaceAll(/<li><p>(.*?)<\/p><(\/?)(ol|li|ul)>/gi, '<li>$1<$2$3>')

const RichTextInput = forwardRef((props: RichTextInputProps, ref: any) => {
  return (
    <RaRichTextInput
      {...props}
      ref={ref}
      parse={removeParagraphListItem}
      format={removeParagraphListItem}
    />
  )
})

export default RichTextInput
