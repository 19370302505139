import { Create } from 'react-admin'

import AuthorAffiliateForm from './AuthorAffiliateForm'

const AuthorAffiliateCreate = props => (
  <Create redirect="list" {...props}>
    <AuthorAffiliateForm />
  </Create>
)

export default AuthorAffiliateCreate
