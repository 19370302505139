import { Edit } from 'react-admin'

import AuthorAffiliateForm from './AuthorAffiliateForm'

const AuthorAffiliateEdit = props => {
  return (
    <Edit undoable={false} {...props}>
      <AuthorAffiliateForm editing />
    </Edit>
  )
}

export default AuthorAffiliateEdit
