import {
  List,
  Datagrid,
  TextField,
  TextInput,
  ReferenceField,
  useRecordContext,
  usePermissions,
} from 'react-admin'
import { Fragment } from 'react'

import FilterComponent from '../../components/Filter'
import DateTimeField from '../../components/DateTimeField'
import { ROLES } from '../../components/constants'
import ExpertBookingActionButton from '../../components/ExpertBookingActionButton'

const ExpertBookingFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </FilterComponent>
)

const ActionField = () => {
  const record = useRecordContext()
  const { permissions } = usePermissions()
  if (!record || permissions !== ROLES.ADMIN) return null

  switch (record.status) {
    case 'waiting':
      return (
        <Fragment>
          <ExpertBookingActionButton
            label="Paid"
            action="paid"
            color="primary"
            record={record}
          />
          <ExpertBookingActionButton
            label="Canceled"
            action="canceled"
            color="error"
            record={record}
          />
        </Fragment>
      )

    case 'paid':
      return (
        <Fragment>
          <ExpertBookingActionButton
            label="Booked"
            action="booked"
            color="primary"
            record={record}
          />
          <ExpertBookingActionButton
            label="Canceled"
            action="canceled"
            color="error"
            record={record}
          />
        </Fragment>
      )
    case 'booked':
      return (
        <Fragment>
          <ExpertBookingActionButton
            label="Completed"
            action="completed"
            color="success"
            record={record}
          />
          <ExpertBookingActionButton
            label="Canceled"
            action="canceled"
            color="error"
            record={record}
          />
        </Fragment>
      )
  }
}

const ExpertBookingList = props => (
  <List
    {...props}
    filters={<ExpertBookingFilter />}
    sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <TextField source="id" />
      <ReferenceField source="bookingUserId" reference="users">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="name" />
      <TextField source="phoneNumber" />
      <TextField source="email" />
      <TextField source="notes" />
      <ReferenceField source="expertId" reference="experts">
        <TextField source="mentor.name" />
      </ReferenceField>
      <TextField source="price" />
      <DateTimeField source="createdAt" />
      <DateTimeField source="updatedAt" />
      <TextField source="status" />
      <ActionField />
    </Datagrid>
  </List>
)

export default ExpertBookingList
