import { Edit } from 'react-admin'

import ExpertForm from './ExpertForm'

const ExpertEdit = props => (
  <Edit undoable={false} {...props}>
    <ExpertForm />
  </Edit>
)

export default ExpertEdit
