import {
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  minValue,
  ImageInput,
  SelectInput,
  DateInput,
  maxValue,
} from 'react-admin'
import Grid from '@mui/material/Grid'

import GridContainer from '../../components/GridContainer'
import BookToolbar from '../../components/BookToolbar'
import CustomImageField from '../../components/CustomImageField'
import { validateRequired } from '../../utils/validate'
import { ROLES } from '../../components/constants'

const statuses = [
  { id: true, name: 'True' },
  { id: false, name: 'False' },
]

const AuthorAffiliateForm = props => {
  return (
    <SimpleForm {...props} toolbar={<BookToolbar />} redirect="list">
      <GridContainer>
        <Grid item xs={6}>
          <ReferenceInput label="Author" source="authorId" reference="authors">
            <AutocompleteInput
              optionText="name"
              options={{
                fullWidth: true,
              }}
              variant="standard"
              filterToQuery={searchText => ({
                name: { $iLike: `%${searchText}%` },
              })}
              validate={validateRequired('Author')}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6}>
          <ReferenceInput
            label="Publisher"
            source="publisherId"
            reference="users"
            perPage={1000}>
            <AutocompleteInput
              optionText="name"
              options={{
                fullWidth: true,
              }}
              variant="standard"
              validate={validateRequired('Publisher')}
              filterToQuery={searchText => ({
                name: { $iLike: `%${searchText}%` },
                role: ROLES.PUBLISHER,
              })}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Status"
            source="status"
            choices={statuses}
            fullWidth
            required
            variant="standard"
            defaultValue={true}
          />
        </Grid>

        <Grid item xs={6}>
          <NumberInput
            label="Commission"
            source="commission"
            fullWidth
            validate={(minValue(0), maxValue(100))}
            variant="standard"
          />
        </Grid>

        <Grid item xs={6}>
          <DateInput
            label="Start date"
            source="startAt"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={6}>
          <DateInput
            label="Expire date"
            source="expireAt"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <ImageInput
            source="bannerImageUrl"
            label="Banner image"
            accept="image/*"
            multiple={false}
            validate={validateRequired('Banner Image')}>
            <CustomImageField source="src" title="Banner image" />
          </ImageInput>
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default AuthorAffiliateForm
