import Grid from '@mui/material/Grid'
import {
  ArrayInput,
  AutocompleteInput,
  ImageInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin'

import countryData from '../author/countries'
import CustomImageField from '../../components/CustomImageField'
import GridContainer from '../../components/GridContainer'
import CustomFileField from '../../components/CustomFileField'
import CustomFileInput from '../../components/CustomFileInput'
import CustomTextInput from '../../components/CustomTextInput'
import RichTextInput from '../../components/RichTextInput'

const CoachForm = props => {
  const { ...rest } = props

  return (
    <SimpleForm {...rest} redirect="list">
      <GridContainer>
        <Grid item xs={6}>
          <CustomTextInput
            source="name"
            fullWidth
            variant="standard"
            helperText={'Không giới hạn, lý tưởng < 25 kí tự'}
            showTextLength
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteInput
            source="country"
            label="Country"
            options={{
              fullWidth: true,
            }}
            variant="standard"
            choices={Object.keys(countryData).map(key => ({
              id: key,
              name: countryData[key]['name']['common'],
            }))}
          />
        </Grid>
        <Grid item xs={12}>
          <ArrayInput source="avatars" fullWidth>
            <SimpleFormIterator>
              <ImageInput
                accept="image/*"
                variant="standard"
                multiple={false}
                helperText={'Tỉ lệ hình 9:16'}>
                <CustomImageField title="Avatar" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid item xs={12}>
          <CustomFileInput
            source="introVideoUrl"
            label="Intro video"
            accept="video/*"
            multiple={false}
            type="introVideo"
            upload={true}
            showProgress
            helperText={'Tỉ lệ video 9:16'}>
            <CustomFileField source="src" title="Intro video" canDownload />
          </CustomFileInput>
        </Grid>
        <Grid item xs={12}>
          <RichTextInput source="about" fullWidth variant="standard" />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            source="headline"
            label="Headline"
            fullWidth
            variant="standard"
          />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default CoachForm
