import Grid from '@mui/material/Grid'
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  FormDataConsumer,
  ImageInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin'
import { RichTextInput } from 'ra-input-rich-text'

import CustomImageField from '../../components/CustomImageField'
import GridContainer from '../../components/GridContainer'
import { validateRequired } from '../../utils/validate'

export const unitOptions = [
  { id: 'minutes', name: 'Minutes' },
  { id: 'hours', name: 'Hours' },
  { id: 'days', name: 'Days' },
]

export const sectionTypeOptions = [
  { id: 'text', name: 'Text' },
  { id: 'image', name: 'Image' },
  { id: 'bullet', name: 'Bullet' },
]

const ExpertForm = props => {
  const { ...rest } = props

  return (
    <SimpleForm {...rest} redirect="list">
      <GridContainer>
        <Grid item xs={6}>
          <ReferenceInput
            variant="standard"
            label="Mentor"
            source="mentorId"
            reference="coaches"
            name="mentorId">
            <AutocompleteInput
              optionText="name"
              options={{
                fullWidth: true,
              }}
              filterToQuery={searchText => ({
                name: { $iLike: `%${searchText}%` },
              })}
              name="mentorId"
            />
          </ReferenceInput>
        </Grid>

        <Grid item xs={12}>
          <BooleanInput
            variant="standard"
            label="Status"
            source="status"
            name="status"
          />
        </Grid>

        <Grid item xs={12}>
          <ArrayInput source="featuredImages" fullWidth>
            <SimpleFormIterator>
              <ImageInput
                accept="image/*"
                variant="standard"
                multiple={false}
                helperText={'Tỉ lệ hình 16:10'}>
                <CustomImageField title="Featured Image" />
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>

        <Grid item xs={12}>
          <ArrayInput
            source="sections"
            label="Sections"
            fullWidth
            validate={[validateRequired('Sections')]}>
            <SimpleFormIterator>
              <NumberInput source="order" />
              <TextInput
                source="title"
                label="Title"
                fullWidth
                variant="standard"
                useScoped
              />
              <SelectInput
                source="type"
                label="Type"
                choices={sectionTypeOptions}
              />

              <FormDataConsumer>
                {({ scopedFormData }) => {
                  if (!scopedFormData?.type) {
                    return null
                  }

                  switch (scopedFormData.type) {
                    case 'text':
                      return (
                        <ArrayInput source="details" label="Items" fullWidth>
                          <SimpleFormIterator>
                            <RichTextInput
                              source="description"
                              label="Description"
                              fullWidth
                              variant="standard"
                              useScoped
                            />
                          </SimpleFormIterator>
                        </ArrayInput>
                      )
                    case 'image':
                      return (
                        <ArrayInput source="details" label="Items" fullWidth>
                          <SimpleFormIterator>
                            <ImageInput
                              accept="image/*"
                              variant="standard"
                              source="image"
                              multiple={false}>
                              <CustomImageField title="Image" />
                            </ImageInput>
                          </SimpleFormIterator>
                        </ArrayInput>
                      )
                    case 'bullet':
                      return (
                        <ArrayInput
                          source="details"
                          label="Bullet Items"
                          fullWidth>
                          <SimpleFormIterator>
                            <TextInput source="icon" />
                            <RichTextInput
                              source="description"
                              label="Description"
                              fullWidth
                              variant="standard"
                              useScoped
                            />
                          </SimpleFormIterator>
                        </ArrayInput>
                      )
                    default:
                      return null
                  }
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>

        <Grid item xs={12}>
          <TextInput source="sessionTitle" />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            source="price"
            label="Price per session"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            source="sessionLength"
            label="Session Length"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            name="sessionUnit"
            source="sessionUnit"
            choices={unitOptions}
          />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default ExpertForm
