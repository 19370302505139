import { Resource } from 'react-admin'
import React from 'react'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import LineStyleIcon from '@mui/icons-material/LineStyle'
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic'
import AlbumIcon from '@mui/icons-material/Album'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import VideocamIcon from '@mui/icons-material/Videocam'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import RedeemIcon from '@mui/icons-material/Redeem'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'

import { BatchCodesCreate, BatchCodesEdit, BatchCodesList } from './batch-codes'
import { ApiCodeCreate, ApiCodeList } from './api-code'
import {
  CollectionCodeCreate,
  CollectionCodeEdit,
  CollectionCodeList,
} from './collection-codes'
import { AffiliateCreate, AffiliateEdit, AffiliateList } from './affiliate'
import { DiscountsCreate, DiscountsEdit, DiscountsList } from './discount'
import { TransactionDiscountList } from './transaction-discounts'
import { UserCreate, UserEdit, UserList } from './user'
import { BookCreate, BookEdit, BookList } from './book'
import { CommissionCreate, CommissionEdit, CommissionList } from './commissions'
import { AuthorCreate, AuthorEdit, AuthorList } from './author'
import { CategoryCreate, CategoryEdit, CategoryList } from './category'
import {
  CategoryGroupCreate,
  CategoryGroupEdit,
  CategoryGroupList,
} from './category-group'
import { CollectionCreate, CollectionEdit, CollectionList } from './collection'
import { TopicCreate, TopicEdit, TopicList } from './topics'
import { SlideshowCreate, SlideshowEdit, SlideshowList } from './slideshow'
import { BannerCreate, BannerEdit, BannerList } from './banner'
import { MusicCreate, MusicEdit, MusicList } from './music'
import {
  BookSummaryCreate,
  BookSummaryEdit,
  BookSummaryList,
} from './book-summary'
import { EbookCreate, EbookEdit, EbookList } from './ebook'
import { MeditationCreate, MeditationEdit, MeditationList } from './meditation'
import { SleepStoryCreate, SleepStoryEdit, SleepStoryList } from './sleep-story'
import { KidsStoryCreate, KidsStoryEdit, KidsStoryList } from './kids-story'
import { NewsFeedCreate, NewsFeedEdit, NewsFeedList } from './news-feed'
import { ReviewList } from './review'
import { PurchaseHistoryList } from './purchase-history'
import {
  FreeAccountsCreate,
  FreeAccountsEdit,
  FreeAccountsList,
} from './free-accounts'
import {
  AudioSeriesCreate,
  AudioSeriesEdit,
  AudioSeriesList,
} from './audio-series'
import { ArticleCreate, ArticleEdit, ArticleList } from './article'
import {
  ManageSubscriptionCreate,
  ManageSubscriptionList,
} from './manage-subscription'
import {
  AffiliatePaymentsCreate,
  AffiliatePaymentsEdit,
  AffiliatePaymentsList,
} from './affiliate-payments'
import { AffiliatePaymentHistoriesList } from './affiliate-payment-histories'
import { OrderCategoryList } from './order-categories'
import { UserDetailsList, UserDetailsShow } from './user-details'
import {
  UserCreditsCreate,
  UserCreditsEdit,
  UserCreditsList,
} from './user-credits'
import { RedeemedCodeList } from './redeemed-code'
import {
  FullAccessAccountsCreate,
  FullAccessAccountsEdit,
  FullAccessAccountsList,
} from './full-access-accounts'
import {
  ReferralBookCreate,
  ReferralBookEdit,
  ReferralBookList,
} from './referral-book'
import { ReferralList } from './referral'
import { KpiDashboard } from './kpi-dashboard'
import { AffiliateDashboard } from './affiliate-dashboard'
import { AffiliateWebOrderList } from './affiliate-web-orders'
import {
  GuidedMeditationCreate,
  GuidedMeditationEdit,
  GuidedMeditationList,
} from './guided-meditations'
import {
  MeditationBackgroundCreate,
  MeditationBackgroundEdit,
  MeditationBackgroundList,
} from './meditation-background'
import {
  MeditationQuoteCreate,
  MeditationQuoteEdit,
  MeditationQuoteList,
} from './meditation-quote'
import { CsUserBookCreate, CsUserBookList } from './cs-user-books'
import {
  CsUserSubscriptionCreate,
  CsUserSubscriptionList,
} from './cs-user-subscriptions'
import { CsUserCodeCreate, CsUserCodeList } from './cs-user-codes'
import {
  CsDeleteAccountCreate,
  CsDeleteAccountList,
} from './cs-delete-accounts'
import {
  CsSwitchAccountCreate,
  CsSwitchAccountList,
} from './cs-switch-accounts'
import { CsMergeAccountCreate, CsMergeAccountList } from './cs-merge-accounts'
import {
  VoiceActorCreate,
  VoiceActorEdit,
  VoiceActorList,
} from './voice-actors'
import {
  MarketingCampaignCreate,
  MarketingCampaignEdit,
  MarketingCampaignList,
} from './marketing-campaigns'
import PromotionList from './configs-promotion/PromotionList'
import PromotionEdit from './configs-promotion/PromotionEdit'
import PromotionNotificationList from './configs-promotion-notification/PromotionNotificationList'
import PromotionNotificationEdit from './configs-promotion-notification/PromotionNotificationEdit'
import PromotionNotificationCreate from './configs-promotion-notification/PromotionNotificationCreate'
import { BundleCreate, BundleEdit, BundleList } from './bundle'
import {
  CrmRevenueReportCreate,
  CrmRevenueReportList,
} from './crm-revenue-reports'
import { CrmCODPaymentEdit, CrmCODPaymentList } from './crm-cod-payments'
import { RedeemVoucherList } from './redeem-vouchers'
import { CollectionCodeTransactionList } from './collection-code-transactions'
import { CampaignEdit, CampaignList } from './campaigns'
import { PodcasterList, PodcasterEdit, PodcasterCreate } from './podcaster'
import { ChannelList, ChannelEdit, ChannelCreate } from './channel'
import { EpisodeList, EpisodeEdit } from './episode'
import { RoleCreate, RoleEdit, RoleList } from './role'
import { ActivityList } from './activity'
import {
  ConfigurationSettingCreate,
  ConfigurationSettingEdit,
  ConfigurationSettingList,
} from './configuration-settings'
import {
  EnglishBookCreate,
  EnglishBookEdit,
  EnglishBookList,
} from './english-book'
import { CourseCreate, CourseEdit, CourseList } from './course'
import { CoachCreate, CoachEdit, CoachList } from './coach'
import { SuggestionList } from './suggestion'
import { CsRevertCodeCreate, CsRevertCodeList } from './cs-revert-codes'
import { LandingPageList, LandingPageEdit } from './landing-page'
import {
  AffiliateCollectionList,
  AffiliateCollectionCreate,
  AffiliateCollectionEdit,
} from './affiliate-collection'
import {
  ChallengeRewardList,
  ChallengeRewardCreate,
  ChallengeRewardEdit,
} from './challenge-reward'
import { ChallengeRewardRedeemList } from './challenge-reward-redeem'
import {
  B2BOrganizationCreate,
  B2BOrganizationEdit,
  B2BOrganizationList,
} from './b2b-organization'
import {
  B2BOrganizationContentCreate,
  B2BOrganizationContentEdit,
  B2BOrganizationContentList,
} from './b2b-organization-content'
import {
  B2BTrialUserCreate,
  B2BTrialUserEdit,
  B2BTrialUserList,
} from './b2b-trial-user'
import { B2BUserList, B2BUserShow } from './b2b-user'
import {
  FeaturedContentList,
  FeaturedContentEdit,
  FeaturedContentCreate,
} from './featured-contents'
import { AdsCreate, AdsEdit, AdsList } from './ads'
import { ExpertCreate, ExpertEdit, ExpertList } from './experts'
import { ExpertBookingList } from './expert-bookings'
import {
  AuthorAffiliateList,
  AuthorAffiliateEdit,
  AuthorAffiliateCreate,
} from './author-affiliates'

const resources = {
  readUsers: <Resource name="users" />,
  readAuthor: <Resource name="authors" />,
  readCoach: <Resource name="coaches" />,
  readCategories: <Resource name="categories" />,
  readTopics: <Resource name="topics" />,
  readCollection: <Resource name="collections" />,
  readBooks: <Resource name="books" />,
  readEbooks: <Resource name="ebooks" />,
  readBookSummaries: <Resource name="book-summaries" />,
  readMusics: <Resource name="musics" />,
  readMeditations: <Resource name="meditations" />,
  readSleepStories: <Resource name="sleep-stories" />,
  readKidsStories: <Resource name="kids-stories" />,
  readAudioSeries: <Resource name="audio-series" />,
  readArticles: <Resource name="articles" />,
  readBatchCodes: <Resource name="batch-codes" list={BatchCodesList} />,
  readCodes: (
    <Resource options={{ label: 'Code' }} name="codes" list={ApiCodeList} />
  ),
  readCollectionCodes: (
    <Resource
      options={{ label: 'Collection code' }}
      name="collection-codes"
      list={CollectionCodeList}
    />
  ),
  readAffiliates: <Resource name="affiliates" list={AffiliateList} />,
  readDiscounts: (
    <Resource
      options={{ label: 'Promotion' }}
      name="discounts"
      list={DiscountsList}
    />
  ),
  readTransactionDiscounts: (
    <Resource
      name="transaction-discounts"
      options={{ label: 'Promo transactions' }}
      list={TransactionDiscountList}
    />
  ),
  activities: (
    <Resource
      name="activities"
      options={{ label: 'Activities' }}
      list={ActivityList}
    />
  ),
  roles: (
    <Resource
      name="roles"
      options={{ label: 'Roles' }}
      list={RoleList}
      edit={RoleEdit}
      create={RoleCreate}
    />
  ),
  users: (
    <Resource
      name="users"
      options={{ label: 'Accounts' }}
      list={UserList}
      edit={UserEdit}
      create={UserCreate}
    />
  ),
  books: (
    <Resource
      name="books"
      list={BookList}
      edit={BookEdit}
      create={BookCreate}
      icon={MenuBookIcon}
    />
  ),
  'english-books': (
    <Resource
      options={{ label: 'English books' }}
      name="english-books"
      list={EnglishBookList}
      edit={EnglishBookEdit}
      create={EnglishBookCreate}
      icon={MenuBookIcon}
    />
  ),
  courses: (
    <Resource
      options={{ label: 'PodCourse' }}
      name="courses"
      list={CourseList}
      edit={CourseEdit}
      create={CourseCreate}
      icon={VideocamIcon}
    />
  ),
  commissions: (
    <Resource
      name="commissions"
      list={CommissionList}
      edit={CommissionEdit}
      create={CommissionCreate}
    />
  ),
  authors: (
    <Resource
      name="authors"
      list={AuthorList}
      edit={AuthorEdit}
      create={AuthorCreate}
    />
  ),
  coaches: (
    <Resource
      name="coaches"
      options={{ label: 'Mentors' }}
      list={CoachList}
      edit={CoachEdit}
      create={CoachCreate}
    />
  ),
  categories: (
    <Resource
      name="categories"
      list={CategoryList}
      edit={CategoryEdit}
      create={CategoryCreate}
    />
  ),
  'category-groups': (
    <Resource
      options={{ label: 'Category groups' }}
      name="category-groups"
      list={CategoryGroupList}
      edit={CategoryGroupEdit}
      create={CategoryGroupCreate}
    />
  ),
  collections: (
    <Resource
      name="collections"
      list={CollectionList}
      edit={CollectionEdit}
      create={CollectionCreate}
    />
  ),
  topics: (
    <Resource
      name="topics"
      list={TopicList}
      edit={TopicEdit}
      create={TopicCreate}
    />
  ),
  slideshows: (
    <Resource
      name="slideshows"
      list={SlideshowList}
      edit={SlideshowEdit}
      create={SlideshowCreate}
    />
  ),
  banners: (
    <Resource
      name="banners"
      list={BannerList}
      edit={BannerEdit}
      create={BannerCreate}
    />
  ),
  musics: (
    <Resource
      name="musics"
      list={MusicList}
      edit={MusicEdit}
      create={MusicCreate}
    />
  ),
  'book-summaries': (
    <Resource
      options={{ label: 'Book summaries' }}
      name="book-summaries"
      list={BookSummaryList}
      edit={BookSummaryEdit}
      create={BookSummaryCreate}
    />
  ),
  ebooks: (
    <Resource
      options={{ label: 'Ebooks' }}
      name="ebooks"
      list={EbookList}
      edit={EbookEdit}
      create={EbookCreate}
    />
  ),
  meditations: (
    <Resource
      name="meditations"
      list={MeditationList}
      edit={MeditationEdit}
      create={MeditationCreate}
    />
  ),
  'sleep-stories': (
    <Resource
      options={{ label: 'Sleep stories' }}
      name="sleep-stories"
      list={SleepStoryList}
      edit={SleepStoryEdit}
      create={SleepStoryCreate}
    />
  ),
  'kids-stories': (
    <Resource
      options={{ label: 'Kids stories' }}
      name="kids-stories"
      list={KidsStoryList}
      edit={KidsStoryEdit}
      create={KidsStoryCreate}
    />
  ),
  'news-feeds': (
    <Resource
      options={{ label: 'News feeds' }}
      name="news-feeds"
      list={NewsFeedList}
      edit={NewsFeedEdit}
      create={NewsFeedCreate}
    />
  ),
  'publisher-dashboard': <Resource name="publisher-dashboard" />,
  'publisher-dashboard-admin': <Resource name="publisher-dashboard-admin" />,
  readAffiliatePurchaseHistories: (
    <Resource name="affiliate-purchase-histories" />
  ),
  reviews: <Resource name="reviews" list={ReviewList} />,
  suggestions: <Resource name="suggestions" list={SuggestionList} />,
  'publisher-purchase-histories': (
    <Resource
      options={{ label: 'Purchase histories' }}
      name="publisher-purchase-histories"
      list={PurchaseHistoryList}
    />
  ),
  'free-accounts': (
    <Resource
      options={{ label: 'Free accounts' }}
      name="free-accounts"
      list={FreeAccountsList}
      edit={FreeAccountsEdit}
      create={FreeAccountsCreate}
    />
  ),
  'audio-series': (
    <Resource
      options={{ label: 'Audio series' }}
      name="audio-series"
      list={AudioSeriesList}
      edit={AudioSeriesEdit}
      create={AudioSeriesCreate}
    />
  ),
  articles: (
    <Resource
      name="articles"
      list={ArticleList}
      edit={ArticleEdit}
      create={ArticleCreate}
    />
  ),
  discounts: (
    <Resource
      options={{ label: 'Promotion' }}
      name="discounts"
      list={DiscountsList}
      edit={DiscountsEdit}
      create={DiscountsCreate}></Resource>
  ),
  'manage-subscriptions': (
    <Resource
      options={{ label: 'Manage Subscriptions' }}
      name="manage-subscriptions"
      list={ManageSubscriptionList}
      create={ManageSubscriptionCreate}
    />
  ),
  'affiliate-payments': (
    <Resource
      name="affiliate-payments"
      options={{ label: 'Affiliate Payment' }}
      list={AffiliatePaymentsList}
      edit={AffiliatePaymentsEdit}
      create={AffiliatePaymentsCreate}
    />
  ),
  'affiliate-payment-histories': (
    <Resource
      name="affiliate-payment-histories"
      options={{ label: 'Payment' }}
      list={AffiliatePaymentHistoriesList}
    />
  ),
  'order-categories': (
    <Resource
      name="order-categories"
      options={{ label: 'Order categories' }}
      list={OrderCategoryList}></Resource>
  ),
  'transaction-discounts': (
    <Resource
      name="transaction-discounts"
      options={{ label: 'Promo transactions' }}
      list={TransactionDiscountList}></Resource>
  ),
  'user-details': (
    <Resource
      options={{ label: 'User detail' }}
      name="user-details"
      list={UserDetailsList}
      show={UserDetailsShow}
    />
  ),
  'user-credits': (
    <Resource
      options={{ label: '[CRM] Add credits' }}
      name="user-credits"
      list={UserCreditsList}
      edit={UserCreditsEdit}
      create={UserCreditsCreate}
    />
  ),
  codes: (
    <Resource
      options={{ label: 'Code' }}
      name="codes"
      list={ApiCodeList}
      create={ApiCodeCreate}
    />
  ),
  'batch-codes': (
    <Resource
      options={{ label: 'Batch code' }}
      name="batch-codes"
      list={BatchCodesList}
      edit={BatchCodesEdit}
      create={BatchCodesCreate}
    />
  ),
  'redeem-code': (
    <Resource
      options={{ label: 'Redeemed code' }}
      name="redeem-code"
      list={RedeemedCodeList}
    />
  ),
  'full-access-accounts': (
    <Resource
      options={{ label: 'Full access accounts' }}
      name="full-access-accounts"
      list={FullAccessAccountsList}
      edit={FullAccessAccountsEdit}
      create={FullAccessAccountsCreate}
    />
  ),
  'featured-contents': (
    <Resource
      options={{ label: 'Featured contents' }}
      name="featured-contents"
      list={FeaturedContentList}
      edit={FeaturedContentEdit}
      create={FeaturedContentCreate}
    />
  ),
  'referral-books': (
    <Resource
      options={{ label: 'Referral Books' }}
      name="referral-books"
      list={ReferralBookList}
      edit={ReferralBookEdit}
      create={ReferralBookCreate}></Resource>
  ),
  'referral-dashboard': (
    <Resource
      options={{ label: 'Referral' }}
      name="referral-dashboard"
      list={ReferralList}
    />
  ),
  affiliates: (
    <Resource
      name="affiliates"
      list={AffiliateList}
      create={AffiliateCreate}
      edit={AffiliateEdit}
    />
  ),
  'affiliate-collections': (
    <Resource
      options={{ label: 'Affiliate Collections' }}
      name="affiliate-collections"
      list={AffiliateCollectionList}
      create={AffiliateCollectionCreate}
      edit={AffiliateCollectionEdit}
    />
  ),
  'kpi-dashboards': (
    <Resource
      options={{ label: 'KPI Dashboard' }}
      name="kpi-dashboards"
      list={KpiDashboard}
      icon={LineStyleIcon}
    />
  ),
  'affiliate-purchase-histories': (
    <Resource
      options={{ label: 'Affiliate Dashboard' }}
      name="affiliate-purchase-histories"
      list={AffiliateDashboard}
    />
  ),
  'affiliate-payment-orders': (
    <Resource
      options={{ label: 'Affiliate Web Orders' }}
      name="affiliate-payment-orders"
      list={AffiliateWebOrderList}
    />
  ),
  'guided-meditations': (
    <Resource
      options={{ label: 'Series Meditation' }}
      name="guided-meditations"
      list={GuidedMeditationList}
      create={GuidedMeditationCreate}
      edit={GuidedMeditationEdit}
    />
  ),
  'meditation-backgrounds': (
    <Resource
      options={{ label: 'Meditation Background' }}
      name="meditation-backgrounds"
      list={MeditationBackgroundList}
      create={MeditationBackgroundCreate}
      edit={MeditationBackgroundEdit}
    />
  ),
  'meditation-quotes': (
    <Resource
      options={{ label: 'Meditation Quote' }}
      name="meditation-quotes"
      list={MeditationQuoteList}
      create={MeditationQuoteCreate}
      edit={MeditationQuoteEdit}
    />
  ),
  'cs-user-books': (
    <Resource
      options={{ label: '[CRM] Add book/ebook' }}
      name="cs-user-books"
      list={CsUserBookList}
      create={CsUserBookCreate}
    />
  ),
  'cs-user-subscriptions': (
    <Resource
      options={{ label: '[CRM] Add subscription' }}
      name="cs-user-subscriptions"
      list={CsUserSubscriptionList}
      create={CsUserSubscriptionCreate}
    />
  ),
  'cs-user-codes': (
    <Resource
      options={{ label: '[CRM] Redeem code' }}
      name="cs-user-codes"
      list={CsUserCodeList}
      create={CsUserCodeCreate}
    />
  ),
  'cs-delete-accounts': (
    <Resource
      options={{ label: '[CRM] Delete account' }}
      name="cs-delete-accounts"
      list={CsDeleteAccountList}
      create={CsDeleteAccountCreate}
    />
  ),
  'cs-switch-accounts': (
    <Resource
      options={{ label: '[CRM] Switch account' }}
      name="cs-switch-accounts"
      list={CsSwitchAccountList}
      create={CsSwitchAccountCreate}
    />
  ),
  'cs-merge-accounts': (
    <Resource
      options={{ label: '[CRM] Merge account' }}
      name="cs-merge-accounts"
      list={CsMergeAccountList}
      create={CsMergeAccountCreate}
    />
  ),
  'cs-revert-codes': (
    <Resource
      options={{ label: '[CRM] Revert code' }}
      name="cs-revert-codes"
      list={CsRevertCodeList}
      create={CsRevertCodeCreate}
    />
  ),
  'collection-codes': (
    <Resource
      options={{ label: 'Collection code' }}
      name="collection-codes"
      list={CollectionCodeList}
      create={CollectionCodeCreate}
      edit={CollectionCodeEdit}
    />
  ),
  'voice-actors': (
    <Resource
      options={{ label: 'Voice Actor' }}
      name="voice-actors"
      list={VoiceActorList}
      create={VoiceActorCreate}
      edit={VoiceActorEdit}
    />
  ),
  'marketing-campaigns': (
    <Resource
      options={{ label: 'Marketing campaigns' }}
      name="marketing-campaigns"
      list={MarketingCampaignList}
      create={MarketingCampaignCreate}
      edit={MarketingCampaignEdit}
    />
  ),
  'promotion-configs': (
    <Resource
      options={{ label: 'Promotions Configs' }}
      name="promotion-configs"
      list={PromotionList}
      edit={PromotionEdit}
    />
  ),
  'promotion-notifications': (
    <Resource
      options={{ label: 'Promotions notifications' }}
      name="promotion-notifications"
      list={PromotionNotificationList}
      edit={PromotionNotificationEdit}
      create={PromotionNotificationCreate}
    />
  ),
  bundles: (
    <Resource
      name="bundles"
      list={BundleList}
      create={BundleCreate}
      edit={BundleEdit}
    />
  ),
  'crm-revenue-reports': (
    <Resource
      options={{ label: '[CRM] Revenue Reports' }}
      name="crm-revenue-reports"
      list={CrmRevenueReportList}
      create={CrmRevenueReportCreate}
    />
  ),
  'payment-cod': (
    <Resource
      options={{ label: '[CRM] COD Payments' }}
      name="payment-cod"
      list={CrmCODPaymentList}
      edit={CrmCODPaymentEdit}
    />
  ),
  'redeem-vouchers': (
    <Resource
      options={{ label: 'Redeem vouchers' }}
      name="redeem-vouchers"
      list={RedeemVoucherList}
    />
  ),
  'collection-code-transactions': (
    <Resource
      options={{ label: 'Collection code transactions' }}
      name="collection-code-transactions"
      list={CollectionCodeTransactionList}
    />
  ),
  campaigns: (
    <Resource
      options={{ label: 'Campaigns' }}
      name="campaigns"
      list={CampaignList}
      edit={CampaignEdit}
    />
  ),
  podcasters: (
    <Resource
      options={{ label: 'Podcasters' }}
      name="podcasters"
      icon={LibraryMusicIcon}
      list={PodcasterList}
      create={PodcasterCreate}
      edit={PodcasterEdit}
    />
  ),
  channels: (
    <Resource
      options={{ label: 'Channels' }}
      name="channels"
      icon={AlbumIcon}
      list={ChannelList}
      create={ChannelCreate}
      edit={ChannelEdit}
    />
  ),
  episodes: (
    <Resource
      options={{ label: 'Episodes' }}
      name="episodes"
      icon={MusicNoteIcon}
      list={EpisodeList}
      edit={EpisodeEdit}
    />
  ),
  'configuration-settings': (
    <Resource
      options={{ label: 'Configuration Settings' }}
      name="configuration-settings"
      list={ConfigurationSettingList}
      create={ConfigurationSettingCreate}
      edit={ConfigurationSettingEdit}
    />
  ),
  'landing-page': (
    <Resource
      options={{ label: 'Landing Page Config' }}
      name="landing-page"
      list={LandingPageList}
      edit={LandingPageEdit}
    />
  ),
  'challenge-rewards': (
    <Resource
      options={{ label: 'Challenge Rewards' }}
      name="challenge-rewards"
      icon={EmojiEventsIcon}
      list={ChallengeRewardList}
      create={ChallengeRewardCreate}
      edit={ChallengeRewardEdit}
    />
  ),
  'challenge-reward-redeems': (
    <Resource
      options={{ label: 'Challenge Reward Redeem' }}
      name="challenge-reward-redeems"
      icon={RedeemIcon}
      list={ChallengeRewardRedeemList}
    />
  ),
  'b2b-organizations': (
    <Resource
      options={{ label: 'B2B Organizations' }}
      name="b2b-organizations"
      icon={BusinessCenterIcon}
      list={B2BOrganizationList}
      create={B2BOrganizationCreate}
      edit={B2BOrganizationEdit}
    />
  ),
  'b2b-organization-contents': (
    <Resource
      options={{ label: 'B2B Organization Contents' }}
      name="b2b-organization-contents"
      icon={BusinessCenterIcon}
      list={B2BOrganizationContentList}
      create={B2BOrganizationContentCreate}
      edit={B2BOrganizationContentEdit}
    />
  ),
  'b2b-users': (
    <Resource
      options={{ label: 'B2B Users ' }}
      name="b2b-users"
      icon={BusinessCenterIcon}
      list={B2BUserList}
      show={B2BUserShow}
    />
  ),
  'b2b-trial-users': (
    <Resource
      options={{ label: 'B2B Trial Users ' }}
      name="b2b-trial-users"
      icon={BusinessCenterIcon}
      list={B2BTrialUserList}
      create={B2BTrialUserCreate}
      edit={B2BTrialUserEdit}
    />
  ),
  ads: (
    <Resource
      options={{ label: 'Fonos Ads' }}
      name="ads"
      list={AdsList}
      create={AdsCreate}
      edit={AdsEdit}
    />
  ),
  experts: (
    <Resource
      options={{ label: 'Experts' }}
      name="experts"
      list={ExpertList}
      create={ExpertCreate}
      edit={ExpertEdit}
    />
  ),
  'expert-bookings': (
    <Resource
      options={{ label: 'Expert Bookings' }}
      name="expert-bookings"
      list={ExpertBookingList}
      // create={ExpertBookingCreate}
      // edit={ExpertBookingEdit}
    />
  ),
  'author-affiliates': (
    <Resource
      options={{ label: 'Author Affiliates' }}
      name="author-affiliates"
      list={AuthorAffiliateList}
      create={AuthorAffiliateCreate}
      edit={AuthorAffiliateEdit}
    />
  ),
}

export default resources
