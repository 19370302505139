import { Create } from 'react-admin'

import ExpertForm from './ExpertForm'

const ExpertCreate = props => (
  <Create redirect="list" {...props}>
    <ExpertForm />
  </Create>
)

export default ExpertCreate
