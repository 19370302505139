import {
  List,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  ReferenceField,
  NumberField,
} from 'react-admin'

import FilterComponent from '../../components/Filter'
import DateTimeField from '../../components/DateTimeField'

const ExpertFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </FilterComponent>
)

const ExpertList = props => (
  <List
    {...props}
    filters={<ExpertFilter />}
    sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <TextField source="id" />
      <ReferenceField source="mentorId" reference="coaches">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="price" />
      <DateTimeField source="createdAt" />
      <DateTimeField source="updatedAt" />
      <TextField source="status" />
      <EditButton />
    </Datagrid>
  </List>
)

export default ExpertList
