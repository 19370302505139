import { useRecordContext } from 'react-admin'

const styles = {
  imageStyle: {
    maxHeight: '10rem',
    margin: 8,
  },
}

const CustomImageField = props => {
  const { className, source, originalSrc = false } = props
  const record = useRecordContext()
  return (
    <img
      id="coverImage"
      alt={record[source] || record}
      src={
        record[source] ||
        (originalSrc ? record : `${process.env.REACT_APP_S3_URL}/${record}`)
      }
      style={styles.imageStyle}
      className={`${className}`}></img>
  )
}

export const CustomS3ImageField = props => {
  const { source } = props
  const record = useRecordContext()
  console.log(record)
  if (!record[source]) {
    return null
  }

  return (
    <img
      alt={record[source]}
      src={`${process.env.REACT_APP_S3_URL}/${record[source]}`}
      style={{ maxHeight: '10rem' }}></img>
  )
}

export default CustomImageField
